import { brandConfig } from '@core-mkt/brands/ins/config';
import type { Env } from '@core-mkt/services/env/env';

export const environment: Env = {
  production: true,
  envMode: 'dev',
  adobeAnalytics: '',
  slugPrefix: '',
  useV3: true,
  craftApiUrl: 'https://craft-v3.ecom-dev.aceable.com/api/',
  xgritCheckoutUrl: 'https://checkout.ecom-dev.driversed.com/checkout',
  xgritApiConfig: {
    url: 'https://stg.xgritapi.com/api/1/',
    aceauth: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
    baseParams: {
      appType: 'RE',
      platform: 'MKT',
      brandId: 'INS',
    },
  },
  defaultOgImage:
    'https://xgrit-ecom.imgix.net/v3_images/transforms/_350x89_crop_center-center_none/ace_ins_logo.svg?v=1730836120&auto=compress,format',
  optimizelyId: '',
  optimizelyHandle: '',
  brandConfig,
  sentryDsn: 'https://73e707345a0f091ee0c4c1165a1cdd9c@o1007654.ingest.us.sentry.io/4508797243949056',
  segmentId: '',
  segmentCdnProxy: '',
  segmentApiProxy: '',
  feefoMarchantId: '',
  twitterHandle: '',
  facebookDomainVerification: '',
  googleSiteVerification: 'YrnFWa0_O7Lyf9GVhk-ZrZDbst_WsLta_9hxzGKkPM0',
  wistiaVerification: '',
  ipifyUrl: '',
  xccApiUrl: '',
  gladlyHelpCenterEnvironment: {
    api: 'https://aceable.us-1.gladly.com',
    orgId: 'VJOTwOvcQ6WJr85bp0qL0g',
    brandId: 'iG8oR56lR16bXhIj8tteFg',
    cdn: 'https://cdn.gladly.com',
    selector: 'gladly-help-center',
  },
};
