import type { InsertableOptions } from '@aceable/core';
import type { AfterViewInit, OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Compiler,
  Component,
  ElementRef,
  Injector,
  Input,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import { AdobeAnalyticService } from '@core-mkt/services/adobe-analytics/adobe-analytics.service';
import { MktComponentRegistryService } from '@core-mkt/services/dynamic-component/single-template-component-registry.service';
import { EmbedStylesService } from '@core-mkt/services/embed-styles/embed-styles.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { GeolocationService } from '@core-mkt/services/geolocation/geolocation.service';
import { HeaderOverrideService } from '@core-mkt/services/header-override/header-override.service';
import { SeoDataService } from '@core-mkt/services/seo-data/seo-data.service';
import { WistiaService } from '@core-mkt/services/wistia/wistia.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'template-single',
  templateUrl: './template-single.component.html',
})
export class TemplateSingleComponent implements AfterViewInit, OnInit {
  @Input() segmentIdOverride?: string;
  @ViewChild('head', { read: ViewContainerRef })
  public head: ViewContainerRef;
  @ViewChild('foot', { read: ViewContainerRef })
  public foot: ViewContainerRef;
  resolverData: PageDataResponse;
  pageData: any;
  formattedData: InsertableOptions[];
  h1Override: string;
  includeOptimizely: boolean;
  optimizelyHandle: string;
  globalOptimizelyFlag: boolean;
  showAmbassadorModal: boolean;
  currentBrand: string;

  constructor(
    private route: ActivatedRoute,
    private embedStylesService: EmbedStylesService,
    private seoDataService: SeoDataService,
    private registryService: MktComponentRegistryService,
    private redactor: WysiwygRedactorService,
    private wistia: WistiaService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private headerOverrideService: HeaderOverrideService,
    private env: EnvService,
    private compiler: Compiler,
    private aarpAnalytics: AdobeAnalyticService,
    private injector: Injector,
    private geolocation: GeolocationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.geolocation.loadLocationCookie();

    this.formattedData = [];
    this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    this.pageData = this.resolverData.craftData;
    this.showAmbassadorModal = this.pageData.entryData.slug === 'home';
    if (this.pageData.styles.embedStyles) {
      this.embedStylesService.addEmbedStyles(this.pageData.styles.embedStyles);
    }
    this.optimizelyHandle = this.env.get.optimizelyHandle;
    if (this.optimizelyHandle.length === 0) {
      this.globalOptimizelyFlag = this.pageData.globalOptimizely.globalOptimizely;
    } else {
      this.globalOptimizelyFlag = this.pageData.globalOptimizely?.[this.optimizelyHandle]?.[0]?.optimizelyEnabled;
    }
    const adobeData = this.pageData.adobe;
    if (adobeData && this.env.get.adobeAnalytics.length > 0) {
      this.aarpAnalytics.initializeAnalytics(this.pageData);
    }
    this.includeOptimizely = this.pageData.optimizely.optimizelyEnabled || this.globalOptimizelyFlag;
    this.h1Override = this.route.snapshot.queryParamMap.get('hlText')?.split('-').join(' ');
    this.pageData.entryData.baseComponents.forEach((section, index) => {
      if (Object.keys(section).length > 1) {
        section.index = index;
        this.formattedData.push({
          className: section.typeHandle,
          data: section,
        });
      }
    });

    this.seoDataService.setSeoProperties(this.pageData.seoContent);
  }

  async ngOnInit(): Promise<void> {
    const brand = this.env.get.brandConfig.id;
    this.currentBrand = brand;
    switch (brand) {
      case 'AA': {
        await import(
          /* webpackChunkName: "aa-banner-module" */
          '@core-mkt/shared/components/banner/banner.module'
        ).then(({ BannerModule }) => this.compileLazyModule(BannerModule, 'head'));
        await import(
          /* webpackChunkName: "aa-header-module" */
          '@core-mkt/brands/aa/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "aa-footer-module" */
          '@core-mkt/brands/aa/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }

      case 'ACE': {
        await import(
          /* webpackChunkName: "ace-banner-module" */
          '@core-mkt/shared/components/banner/banner.module'
        ).then(({ BannerModule }) => this.compileLazyModule(BannerModule, 'head'));
        await import(
          /* webpackChunkName: "ace-header-module" */
          '@core-mkt/brands/ace/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "ace-footer-module" */
          '@core-mkt/brands/ace/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }

      case 'AARP': {
        await import(
          /* webpackChunkName: "aarp-header-module" */
          '@core-mkt/brands/aarp/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "aarp-footer-module" */
          '@core-mkt/brands/aarp/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }

      case 'DEC': {
        await import(
          /* webpackChunkName: "dec-header-module" */
          '@core-mkt/brands/dec/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "dec-footer-module" */
          '@core-mkt/brands/dec/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }

      case 'INS': {
        await import(
          /* webpackChunkName: "ins-header-module" */
          '@core-mkt/brands/ins/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "ins-footer-module" */
          '@core-mkt/brands/ins/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }

      case 'MTG': {
        await import(
          /* webpackChunkName: "mtg-header-module" */
          '@core-mkt/brands/mtg/src/app/components/header/header.module'
        ).then(({ HeaderModule }) => this.compileLazyModule(HeaderModule, 'head'));
        await import(
          /* webpackChunkName: "mtg-footer-module" */
          '@core-mkt/brands/mtg/src/app/components/footer/footer.module'
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule, 'foot'));
        break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.headerOverrideService.overrideH1Tag(this.renderer, this.elementRef, this.h1Override);

    this.redactor.makeYouTubeIframeResponsive(this.renderer);

    this.redactor.updateLocation();
    this.wistia.setRendererForWistiaPosters(this.renderer);
  }

  private compileLazyModule(module, target: string): void {
    this.compiler.compileModuleAsync(module).then((moduleFactory) => {
      // Create a moduleRef, resolve an entry component, create the component
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = (moduleRef.instance as any).resolveComponent();
      if (target === 'head') {
        this.head.createComponent(componentFactory);
      } else {
        this.foot.createComponent(componentFactory);
      }
    });
  }
}
